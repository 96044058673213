@import url(https://fonts.googleapis.com/css2?family=Cinzel&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Antic+Slab&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



body{background:url(/static/media/lightpaperfibers_@2X.90a68307.png);width:100%;padding-bottom:4rem}#root{padding-bottom:2rem}

.navLinks{list-style-type:none;display:flex;flex-direction:row;justify-content:space-evenly;padding:0}.navLinks a{color:#2e2e1f;text-decoration:none;font-size:2rem;font-family:"Cinzel", serif}.navBar{width:auto;padding:10px;background-color:rgba(255,148,77,0.7)}@media screen and (max-width: 1068px){.navLinks a{font-size:1.2rem}}@media screen and (max-width: 768px){.navLinks{display:flex;flex-flow:row wrap}.navLinks a{font-size:1rem;padding:0 20px;padding:5px 10px}.navBar{padding:1px}}

.title{text-align:center;font-size:4rem;font-family:"Cinzel", serif;padding-top:0 !important}@media screen and (max-width: 768px){.title{font-size:1.5rem}}

.container{font-family:"Antic Slab", serif;text-align:center;margin:1rem 6rem;padding-top:1rem;line-height:1.6rem}@media screen and (max-width: 768px){.container{margin:0.5rem 2rem;line-height:1.2rem}}

.slider-wrapper{padding-top:1rem;width:500px;margin:auto}.slick-slide{position:relative;display:flex;justify-content:center;vertical-align:middle}.slick-track{display:flex;align-items:center}.slick-slide:focus{outline:none}.slick-slide-image{max-width:100%;margin:auto;height:375px}.slick-prev{left:-52px !important}.slick-next:before,.slick-prev:before{content:"" !important}.next-slick-arrow,.prev-slick-arrow{color:black;font-size:30px}.next-slick-arrow{margin-left:40px}.prev-slick-arrow{margin-right:40px}.slide-label{text-align:center;font-family:"Antic Slab", serif}.slide-link{display:block;width:100%;text-decoration:none;color:black;text-align:center;font-family:"Antic Slab", serif}@media screen and (max-width: 768px){.slider-wrapper{padding-top:1rem;width:350px}.next-slick-arrow,.prev-slick-arrow{font-size:20px}.slick-slide{width:auto}.slick-slide-image{height:300px}}@media screen and (max-width: 450px){.next-slick-arrow,.prev-slick-arrow{display:none}.slick-slide{width:auto}.slick-slide-image{height:275px}}

.accordion-wrapper{width:70%;margin:auto}.accordion-title{background-color:rgba(102,204,153,0.7);color:#2e2e1f;font-family:"Antic Slab", serif;font-size:1.5rem;cursor:pointer;padding:0.7rem;margin-top:1rem;border:none;outline:none;transition:0.4s;text-align:center}.active,.accordion-title:hover{background-color:rgba(57,172,115,0.7)}.accordion-panel{padding:0 18px;overflow:hidden;text-align:justify;font-family:"Antic Slab", serif}@media screen and (max-width: 768px){.accordion-title{font-size:1rem}.accordion-wrapper{width:90%}}

.subtitles{text-align:center;font-family:"Antic Slab", serif;font-size:2rem;margin-top:2rem;margin-bottom:1rem;color:#2e2e1f}@media screen and (max-width: 768px){.subtitles{font-size:1.5rem}}@media screen and (max-width: 450px){.subtitles{font-size:1rem;font-weight:bold}}

.liste-education{list-style-type:none;margin:0;padding:0}.links-education{text-decoration:underline}

.image-container{text-align:center}.image{width:4rem;padding:5px}

#container{font-family:"Antic Slab", serif;position:fixed;bottom:0.5rem;left:1rem;box-sizing:border-box;background-color:#e6e6e6}@media (max-width: 768px){#container{display:none}}#button-container{display:flex;align-items:center;cursor:pointer;padding:0 10px}#share-icon{width:30px;rotate:180deg;padding-right:8px}#modal-container{position:fixed;z-index:1;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:rgba(0,0,0,0.4)}#modal{background-color:#e6e6e6;margin:15% auto;padding:10px;width:30%}#text{text-align:center}#links{text-align:center}#icons{padding:1rem;width:50px;margin:auto}@media (max-width: 768px){#icons{width:30px}}#share-mobile-container{font-family:"Antic Slab", serif;position:fixed;bottom:0;left:0;box-sizing:border-box;background-color:#e6e6e6;width:100%}@media (min-width: 768px){#share-mobile-container{display:none}}@media (max-width: 768px){#share-mobile-container{display:block}}#content{display:flex;align-items:center;margin:auto;width:-webkit-max-content;width:-moz-max-content;width:max-content}

