.subtitles {
  text-align: center;
  font-family: "Antic Slab", serif;
  font-size: 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: rgb(46, 46, 31);
}

@media screen and (max-width: 768px) {
  .subtitles {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 450px) {
  .subtitles {
    font-size: 1rem;
    font-weight: bold;
  }
}
