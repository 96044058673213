#container {
  font-family: "Antic Slab", serif;
  position: fixed;
  bottom: 0.5rem;
  left: 1rem;
  box-sizing: border-box;
  background-color: #e6e6e6;
  @media (max-width: 768px) {
    display: none;
  }
}

#button-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 10px;
}

#share-icon {
  width: 30px;
  rotate: 180deg;
  padding-right: 8px;
}

#modal-container {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

#modal {
  background-color: #e6e6e6;
  margin: 15% auto;
  padding: 10px;
  width: 30%;
}

#text {
  text-align: center;
}

#links {
  text-align: center;
}

#icons {
  padding: 1rem;
  width: 50px;
  margin: auto;
  @media (max-width: 768px) {
    width: 30px;
  }
}

#share-mobile-container {
  font-family: "Antic Slab", serif;
  position: fixed;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  background-color: #e6e6e6;
  width: 100%;
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 768px) {
    display: block;
  }
}

#content {
  display: flex;
  align-items: center;
  margin: auto;
  width: max-content;
}
