.title {
  text-align: center;
  font-size: 4rem;
  font-family: "Cinzel", serif;
  padding-top: 0 !important;
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 1.5rem;
  }
}
