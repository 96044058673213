@import url("https://fonts.googleapis.com/css2?family=Cinzel&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Antic+Slab&display=swap");

body {
  background: url("./assets/lightpaperfibers/lightpaperfibers_@2X.png");
  width: 100%;
  padding-bottom: 4rem;
}
#root {
  padding-bottom: 2rem;
}
