.navLinks {
  a {
    color: rgb(46, 46, 31);
    text-decoration: none;
    font-size: 2rem;
    font-family: "Cinzel", serif;
  }
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0;
}

.navBar {
  width: auto;
  padding: 10px;
  background-color: rgba(255, 148, 77, 0.7);
}

@media screen and (max-width: 1068px) {
  .navLinks {
    a {
      font-size: 1.2rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .navLinks {
    display: flex;
    flex-flow: row wrap;
    a {
      font-size: 1rem;
      padding: 0 20px;
      padding: 5px 10px;
    }
  }
  .navBar {
    padding: 1px;
  }
}
